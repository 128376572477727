body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eeeef0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  -webkit-appearance: none;
}

*:focus {
  outline: none;
}
.conversation-list {
  display: flex;
  flex-direction: column;
}
.conversation-search {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.conversation-search-input {
  background: #f4f4f8;
  padding: 8px 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
}

.conversation-search-input::-webkit-input-placeholder {
  text-align: center;
}

.conversation-search-input::-ms-input-placeholder {
  text-align: center;
}

.conversation-search-input::placeholder {
  text-align: center;
}

.conversation-search-input:focus::-webkit-input-placeholder {
  text-align: left;
}

.conversation-search-input:focus::-ms-input-placeholder {
  text-align: left;
}

.conversation-search-input:focus::placeholder {
  text-align: left;
}
.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}
.toolbar {
  display: flex;
  align-items: center;

  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;

  position: -webkit-sticky;

  position: sticky;
  top: 0px;
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
}

.toolbar-title {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
}

.left-items, .right-items {
  flex: 1 1;
  padding: 10px;
  display: flex;
}

.right-items {
  flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}
.toolbar-button {
  color: #007aff;
  font-size: 28px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.toolbar-button:hover {
  cursor: pointer;
  color: #0063ce;
}

.toolbar-button:active {
  color: #007aff;
  opacity: 0.25;
}
.compose {
  padding: 10px;
  display: flex;
  align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: fixed;
  width: calc(100% - 20px);
  bottom: 0px;
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
}

.compose-input {
  flex: 1 1;
  border: none;
  font-size: 14px;
  height: 40px;
  background: none;
}

.compose-input::-webkit-input-placeholder {
  opacity: 0.3;
}

.compose-input::-ms-input-placeholder {
  opacity: 0.3;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}
.message-list-top {
  width: 100%;
  height: 60px;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  position:absolute;
}
.message-list-container {
  width: 100%;
  top: 60px;
  bottom: 60px;
  position: fixed;
  overflow: auto;
  
}

.message-list-input {
  position: fixed;
  height: 120px;
  overflow:hidden;
}
.messenger {
  display: grid;
  width: 100%;
  height: 100vh;
  background: #eeeef1;

  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}
