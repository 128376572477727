.message-list-top {
  width: 100%;
  height: 60px;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  position:absolute;
}
.message-list-container {
  width: 100%;
  top: 60px;
  bottom: 60px;
  position: fixed;
  overflow: auto;
  
}

.message-list-input {
  position: fixed;
  height: 120px;
  overflow:hidden;
}